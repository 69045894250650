



































































import Vue from 'vue';
import Hero from '@/components/Hero.vue';
import { NameOfCountry } from '@/modules/NameOfCountry';
import { CountryCode } from '@/types/CountryCode';
import axios from 'axios';
import { Platform } from '@/modules/Platform';
import mixins from 'vue-typed-mixins';
import { ViewMixin } from '@/mixins/ViewMixin';

export default mixins(ViewMixin).extend({
  name: 'Contact',

  components: {
    Hero,
  },

  data() {
    return {
      form: {
        userAgent: navigator.userAgent,
        home: this.$store.state.home,
        timezone: this.$store.state.homeTimezone,
        version: process.env.PACKAGE_VERSION,
        platform: Platform.type,
        destination: this.$route.query.destination,

        // The rest are populated dynamically by template
      },
      valid: false,
      error: false,
      loading: false,
      sent: false, // One-time flag
      emailRules: [
        (value: string) => {
          if (value && value.length > 0) {
            // eslint-disable-next-line max-len
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || 'Invalid e-mail.';
          }

          return true;
        },
      ],
    };
  },

  methods: {
    checkForm(event: Event) {
      event.preventDefault();

      const valid = (this.$refs.form as any).validate();

      if (valid) {
        this.submit(this.form);
      }
    },

    submit(payload: Record<string, any>) {
      this.loading = true;
      this.error = false;

      axios
        .post(process.env.VUE_APP_FORM_POST || '', payload)
        .then(() => this.sent = true)
        .catch((err) => {
          console.error(err);
          this.error = true;
        })
        .finally(() => this.loading = false);
    },
  },

  computed: {
    title() {
      const destination = NameOfCountry.get(this.$route.query.destination as CountryCode | null);
      if (destination) {
        return `Suggestion for ${destination}`;
      }

      return 'Get in Touch';
    },
  },

  metaInfo: {
    title: 'Contact',
  },
});
